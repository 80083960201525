export default [
  {
    title: 'NO',
    slots: { customRender: 'no' },
  },
  {
    title: 'Tanggal Scheduler',
    dataIndex: 'trans_date',
    format: 'DD MMMM YYY',
  },
  {
    title: 'Fungsi',
    dataIndex: 'function',
  },
  {
    title: 'Tipe',
    dataIndex: 'type',
  },
  {
    title: 'Kode Distributor',
    dataIndex: 'vendor_code',
  },
  {
    title: 'Nama Distributor',
    dataIndex: 'vendor_name',
  },
  {
    title: 'Status',
    dataIndex: 'status_text',
    slots: { customRender: 'status' },
  },
  {
    title: 'Updated Date',
    dataIndex: 'time',
  },
  {
    title: 'Total Data',
    dataIndex: 'data_count',
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
